<template>
  <div class="courseDetails">
    <div class="courseDetails_nav">
      <div class="courseDetails_nav_box margin-auto w-1200 flexs">
        <el-breadcrumb separator-class="el-icon-arrow-right">
          <el-breadcrumb-item :to="{path:'/microlecture?type=1'}">微课堂</el-breadcrumb-item
          >
          <el-breadcrumb-item>课程详情</el-breadcrumb-item>
        </el-breadcrumb>
      </div>
    </div>
    <div class="courseDetails_main p-t-40 p-b-40">
      <div class="courseDetails_main_box margin-auto w-1200 fl">
        <div class="courseDetails_main_video">
          <img :src="courseDetails.image" alt="">
          <!-- <video controls :src="video" v-else></video> -->
        </div>
        <div class="courseDetails_main_right p-l-20 p-r-20">
          <div class="courseDetails_main_name p-t-40 p-b-40">{{ courseDetails.title }}</div>
          <div class="courseDetails_main_txt m-b-20" v-if="from.type == 2">课程简介：</div>
          <div class="courseDetails_main_title color-6">{{ courseDetails.text }}</div>
        </div>
      </div>
    </div>
    <div class="courseDetails_footer m-t-30">
      <div class="courseDetails_footer_box margin-auto w-1200 b-c-f">
        <ul class="courseDetails_footer_ul center">
          <li @click="changeIndex(index)" v-for="(item,index) in 2" :key="index" class="center" :class="{active:index == courseIndex}">{{ index == 0 ? '课程概述' : '课程目录' }}</li>
        </ul>
         
        <div class="courseDetails_footer_bot">
          <ul class="courseDetails_footer_list p-t-20" v-if="courseIndex == 1">
            <li @click="goDetails(item.id)" class="flexs p-l-20" v-for="(item,index) in videoList" :key="index">
              <img src="../../assets/image/microlecture/icon.png" alt="">
              <span>{{ item.title }}</span>
            </li>
          </ul>

          <div class="courseDetails_footer_txt p-t-30 p-b-30" v-else v-html="courseDetails.content">

          </div>
          <div class="paging" v-if="courseIndex == 1 && videoList.length != 0">
            <el-pagination
              background
              @current-change="changePage"
              layout="prev, pager, next, jumper, ->, total"
              :total="total">
            </el-pagination>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  data() {
    return {
      id:null,//课程id
      courseIndex:0,//
      courseDetails:{},//课程详情
      videoList:[],//视频列表
      total:0,//总页数
      video:'',
      from:{
        page:1,
        id:'',
        type:1,//1视频2图文
      }
    };
  },
  methods:{
    goDetails (id) {
      this.$router.push({path:this.from.type == 1 ? '/video' : '/message',query:{id,type:this.from.type,video_id:this.from.id}})
    },
    changePage (e) {
      this.from.page = e
      this.getClassroomDetail()
    },
    changeIndex (index) {
      this.courseIndex = index
    },
    goRutern () {
      this.$router.go(-1)
    },
    getClassroomDetail () {
      // if (this.from.type == 1) {
      //    this.$axios.classroomCatalogueDetail(this.from).then(res=>{
      //     this.video = res.data.vediofile
      //   })
      // }
     
      this.$axios.classroomDetail(this.from).then(res=>{
        this.courseDetails = res.data
      })
      this.$axios.classroomCatalogue(this.from).then(res=>{
        this.videoList = res.data.data
        this.total = res.data.total
      })
    }
  },
  created() {
    this.from.id = this.$route.query.id
    this.from.type = this.$route.query.type
    this.getClassroomDetail()
  },
};
</script>

<style lang="less" scoped>
/deep/ .el-breadcrumb__inner {
  color: #ffffff !important;
}
/deep/ .is-link {
  color: #CCCCCC !important;
}

.courseDetails_nav {
 
  background: #282828;
  .courseDetails_nav_box {
     height: 52px;
  }
}
.courseDetails_main {
  background: #191919;
  .courseDetails_main_video {
    width: 840px;
    height: 630px;
    video {
      width: 100%;
      height: 100%;
      display: block;
    }
  }
  .courseDetails_main_right {
    flex: 1;
    .courseDetails_main_name {
      font-size: 24px;
      color:#ffffff ;
      line-height: 32px;
    }
    .courseDetails_main_txt {
      font-size: 20px;
      color: #ffffff;
    }
    .courseDetails_main_title {
      font-size: 16px;
      line-height: 28px;
    }
  }
}
.courseDetails_footer_ul {
  border-bottom: 1px solid #EBEBEB;
  li {
    cursor: pointer;
    height: 78px;
    color: #666666;
    font-size: 20px;
    &:first-child {
      margin-right: 300px;
    }
  }
  .active {
    color: #3273F6;
    font-weight: bold;
    position: relative;
    &::after {
      content: '';
      bottom: 0;
      left: 50%;
      transform: translateX(-50%);
      position: absolute;
      width: 28px;
      height: 2px;
      background: #3273F6;
    }
  }
}
.courseDetails_footer_bot {
  padding: 0 50px;
  .courseDetails_footer_list {
    li {
      height: 64px;
      cursor: pointer;
      background: #F9FBFF;
      margin-bottom: 12px;
      &:last-child {
        margin-bottom: 0;
      }
      img {
        width: 24px;
        height: 24px;
        margin-right: 10px;
      }
      span {
        color: #333333;
        font-size: 18px;
      }
    }
  }
}

</style>
